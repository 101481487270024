<template>
    <div class="article-panel">
        <div class="article-group">
            <div class="article-hd">
                <span class="avatar">
                    <img :src="util.reImg(avatar)" />
                </span>

                <h4 class="name">{{value.name}}</h4>

                <el-button v-text="subscribeText" @click="keep"></el-button>

                <div class="guide">
                    <span>
                        <strong>{{subscribe_count}}人</strong>
                        <em>订阅人数</em>
                    </span>
                    <span>
                        <strong>{{value.article_count}}篇</strong>
                        <em>文章</em>
                    </span>
                </div>
            </div>

            <div class="article-bd">
                <h5>文章列表</h5>

                <div v-if="articleList && articleList.length>0">
                    <ul class="article-items" v-loading="loading">
                        <router-link
                            tag="li"
                            :to="{path:'/article/detail/'+item.id}"
                            v-for="(item,index) in articleList"
                            :key="index"
                            class="item"
                            style="padding:0;padding:20px 0;"
                        >
                            <a href="jacascript:void(0)" class="nav-link">
                                <div class="pic" style="width:100px;height:100px;float:left;">
                                    <img :src="util.reImg(item.img)" />
                                </div>
                                <div style="width:780px;float:right;">
                                    <div
                                        class="hd"
                                        style="margin:0;padding:0;height:30px;overflow:hidden;"
                                    >
                                        <em class="guangfang" v-if="item.is_official">官方</em>
                                        <h4 class="title">
                                            <i :class="{'jk-icon-huo':item.is_hot === 1}"></i>
                                            {{item.title}}
                                        </h4>
                                    </div>
                                    <div
                                        class="bd"
                                        style="height:30px;line-height:30px;margin:0;padding:0;margin:4px 0 0 0;overflow:hidden;"
                                    >
                                        <div class="tag" style="margin-top:0px;">
                                            <!-- <span class="jinghua" v-if="item.is_jh">精华</span>
                                            <span
                                                v-for="(tag,tagIndex) in item.position_arr"
                                                :key="tagIndex"
                                                v-text="tag"
                                            ></span>-->
                                            <span class="jinghua" v-text="item.cate_id.value"></span>
                                        </div>
                                        <div
                                            class="download"
                                            v-if="item.annex_count>0"
                                            style="margin:0;padding:0;"
                                        >
                                            <span
                                                v-if="item.download_point_count==0"
                                                style="line-height:30px;"
                                            >
                                                <em class="orange">免费下载</em>
                                            </span>
                                            <span
                                                v-if="item.download_point_count>0"
                                                style="line-height:30px;"
                                            >
                                                下载所需点券:
                                                <em
                                                    class="orange"
                                                >{{item.download_point_count}}点</em>
                                                <em
                                                    v-if="item.download_price>0"
                                                >（{{item.download_price}}元）</em>
                                            </span>
                                            <span style="line-height:30px;">
                                                下载次数:
                                                <em class="blue">{{item.download_count}}次</em>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="ftr" style="margin-top:14px;height:20px;">
                                        <span>
                                            <i class="jk-icon-liulanliang"></i>
                                            {{item.view_count}}
                                        </span>
                                        <span>
                                            <i class="jk-icon-dianzanshu"></i>
                                            {{item.like_count}}
                                        </span>
                                        <time class="date">{{item.create_time}}</time>
                                    </div>
                                </div>
                            </a>
                        </router-link>
                    </ul>
                    <pagination
                        v-if="articleList"
                        :pageSize="pageSize"
                        :currentPage="currentPage"
                        :pageTotal="pageTotal"
                        @current-change="newPage"
                    ></pagination>
                </div>

                <div v-else>
                    <p style="width:100%;text-align:center;height:300px;line-height:300px;font-size:14px;color:#999;">暂未发布资源</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Pagination from "@/components/common/Pagination.vue";
export default {
    name: "articleIndex",
    props: ['value'],
    components: {
        Pagination //文章
    },
    computed: {
        uk: function () {
            return this.value.uk
        }
    },
    data() {
        return {
            currentPage: 1,
            pageSize: 10,
            pageTotal: 0,
            articleList: '',
            loading: false,
            avatar: "",
            subscribe_count: 0,
            subscribeText: "订阅"
        };
    },
    created() {
        this.loadListData(); //加载文章信息和作者信息
        this.subscribe(); //检查是否订阅作者
        this.avatar = this.value.avatar ? this.value.avatar : this.websiteConfigs.sourceUrl + "/upload/image/geek_avatar.png";//加载头像
    },
    methods: {
        //资源分享列表
        loadListData() {
            let _this = this;
            _this.loading = true;
            _this.articleList = '';

            this.post(
                "/user/index/article_list",
                {
                    uk: _this.$route.query.uk,
                    page: _this.currentPage,
                    limit: _this.pageSize
                },
                function (res) {
                    _this.loading = false;
                    if (res.code == 200) {
                        _this.articleList = res.data.list;
                        _this.pageTotal = res.data.count;
                    }
                }
            );
        },

        //分页
        newPage(page) {
            let _this = this;
            _this.currentPage = page;
            _this.loadListData();
        },

        //关注用户
        keep(e) {
            let _this = this;
            this.post(
                "/user/user/collect_user",
                {
                    uk: this.$route.query.uk
                },
                function (res) {
                    if (res.code == 200) {
                        if (res.data == 1) {
                            //关注成功
                            _this.subscribe_count = _this.subscribe_count + 1;
                            _this.subscribeText = "已关注";
                            _this.$message.success(res.msg);
                        } else {
                            //取消关注
                            _this.subscribe_count = _this.subscribe_count - 1;
                            _this.subscribeText = "关注";
                            _this.$message.success(res.msg);
                        }
                    } else {
                        _this.$message.error(res.msg);
                    }
                }
            );
        },

        //检查是否已经关注了用户
        subscribe() {
            let _this = this;
            this.post(
                "/user/index/is_subscribe",
                {
                    uk: this.$route.query.uk
                },
                function (res) {
                    if (res.code == 200) {
                        if (res.data.is_subscribe == 1) {
                            _this.subscribeText = "已关注";
                            _this.subscribe_count = res.data.subscribe_count;
                        } else {
                            _this.subscribeText = "关注";
                        }
                    } else {
                        _this.$message.error(res.msg);
                    }
                }
            );
        },
    }
};
</script>
<style lang="less">
</style>





